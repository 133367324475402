@import '../../styles/variables';

.sidePanel {
  display: flex;
  flex-direction: column;
  width: 23.5vw;
  height: 100%;
  border-right: 1px solid $lightWhiteBorder;
  flex-shrink: 0;

  .tabs {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding-bottom: 8px;
    border-bottom: 1px solid $lightWhiteBorder;

    li {
      padding: 16px 24px;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      cursor: pointer;

      img {
        width: 23px;
        height: auto;
        margin-right: 5px;
      }

      .loader {
        width: 23px;
        height: 23px;
        border-radius: 100%;
        margin-right: 5px;
        border: 1px solid $white;
        border-right-color: $borderWhite;
        animation: rotate infinite 2s ease-in-out;
      }

      p {
        color: $white80;
      }

      &.active {
        background: linear-gradient(90deg, rgba(59, 110, 113, 0.30) 0%, rgba(62, 83, 137, 0.30) 50%, rgba(52, 46, 122, 0.30) 100%), rgba(255, 255, 255, 0.10);
        color: $white;
      }

      &:hover {
        background: $borderWhite;
      }
    }
  }

  .tncFooter {
    padding: 12px 24px;
    column-gap: 12px;
    row-gap: 6px;
    border-top: 1px solid $lightWhiteBorder;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    position: sticky;
    bottom: 0;

    .externalLinks {
      display: flex;
      align-items: center;

      a {
        font-size: 10px;
        font-weight: 400;
        color: $white60;
        text-decoration: none;

        &:first-child {
          margin-right: 12px;
        }

        &:active {
          color: $lightBlack;
          text-decoration: none;
        }
      }
    }

    .copyright {
      color: $white30;
      font-size: 10px;
      font-weight: 400;
      align-self: normal;
    }
  }

  .recentChats {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    padding-top: 12px;
    overflow-y: overlay;
    overscroll-behavior: auto;

    .chatTitle {
      font-size: 10px;
      font-weight: 500;
      line-height: 125%;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      margin: 0 24px 12px;
      color: $white70;
    }

    .chatsSection {
      flex-grow: 1;
      position: relative;
    }
  }
}

.overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: $black70;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}